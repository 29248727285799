// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RichText } from 'prismic-reactjs'

import { colors } from '@tofu/shared/theme'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Container } from '@tofu/shared/ui/atoms/container'

import { getFromMap } from '@tofu/shared/utils/get-from-map'

import { TSliceText } from './slice-text.types'
import { containerMap } from './slice-text.constants'

export const SliceText: TSliceText = ({
  align,
  width,
  text_style,
  text,
  theme
}) => {
  const maxWidth = getFromMap(containerMap, width) || 0

  return (
    <Box bg={colors[theme].main}>
      <Container
        maxWidth={maxWidth}
        as='div'
        align={align}
        color={colors[theme].contrastText}
        textStyle={text_style}
      >
        {RichText.render(text)}
      </Container>
    </Box>
  )
}
